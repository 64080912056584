import TextInput from 'atoms/CustTextInput';
import PhoneNumberInput from 'atoms/PhoneNumberInput';
import { numbersOnly, required } from 'utils/validations';

const EntityDetails = (props) => {
	const { roi, isMobile, handleChange, handleNumberChange, handleCheckBox } =
		props;

	const inputGroupName = `${
		isMobile ? 'row' : 'd-flex pb-3'
	} justify-content-center`;
	const inputClassName = `form-group ${isMobile ? 'col-auto' : 'col-6'}`;
	const inputStyle = { width: isMobile && '100%', maxWidth: '350px' };

	return (
		<div>
			<div className="d-flex p-3 pt-1 flex-column form-group mb-0">
				<div className="d-flex">
					<p className="mx-auto">
						I {roi.clientAuthName && `, ${roi.clientAuthName}, `}authorize Mind
						Therapy Clinic, provider of my psychiatric care, to
					</p>
				</div>
				<div
					className={`${
						!isMobile && 'd-flex'
					} align-items-center justify-content-center`}
				>
					<div
						className={`form-group ${
							isMobile ? 'row mx-0' : 'col-4'
						} d-flex align-items-center`}
					>
						<div className="form-group col-6 border border-right-0 p-2 my-1">
							<div className="custom-control custom-checkbox">
								<input
									type="checkbox"
									className="custom-control-input"
									id="checkSend"
									onChange={handleCheckBox}
									name="sendChecked"
									checked={roi.sendChecked}
								/>
								<label
									className="custom-control-label font-15"
									htmlFor="checkSend"
								>
									Send
								</label>
							</div>
						</div>
						<div className="form-group col-6 border p-2 my-1">
							<div className="custom-control custom-checkbox">
								<input
									type="checkbox"
									className="custom-control-input"
									id="checkRecieve"
									onChange={handleCheckBox}
									name="receiveChecked"
									checked={roi.receiveChecked}
								/>
								<label
									className="custom-control-label font-15"
									htmlFor="checkRecieve"
								>
									Receive
								</label>
							</div>
						</div>
					</div>
					<p className={`font-16 ${isMobile && 'row mx-0'}`}> my record</p>
					<div
						className={`form-group ${
							isMobile ? 'row mx-0' : 'col-4'
						} d-flex align-items-center`}
					>
						<div className="form-group col-6 border border-right-0 p-2 my-1">
							<div className="custom-control custom-checkbox">
								<input
									type="checkbox"
									className="custom-control-input"
									id="checkTo"
									onChange={handleCheckBox}
									name="sendChecked"
									checked={roi.sendChecked}
								/>
								<label
									className="custom-control-label font-15"
									htmlFor="checkTo"
								>
									To
								</label>
							</div>
						</div>
						<div className="form-group col-6 border p-2 my-1">
							<div className="custom-control custom-checkbox">
								<input
									type="checkbox"
									className="custom-control-input"
									id="checkFrom"
									onChange={handleCheckBox}
									name="receiveChecked"
									checked={roi.receiveChecked}
								/>
								<label
									className="custom-control-label font-15"
									htmlFor="checkFrom"
								>
									From
								</label>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className={inputGroupName}>
				<div className={inputClassName} style={inputStyle}>
					<TextInput
						label="NAME*"
						id="entityName"
						value={roi.entityName}
						placeholder="Name"
						handleInput={handleChange}
						validations={[required]}
					/>
				</div>
				<div className={inputClassName} style={inputStyle}>
					<TextInput
						label="ENTITY*"
						id="entityType"
						value={roi.entityType}
						placeholder="Entity"
						handleInput={handleChange}
						validations={[required]}
					/>
				</div>
			</div>
			<div className={inputGroupName}>
				<div className={inputClassName} style={inputStyle}>
					<PhoneNumberInput
						id="entityPhone"
						label="PHONE NO*"
						value={roi.entityPhone}
						placeholder="Enter 10 Digit Phone Number"
						handleInput={handleNumberChange}
					/>
				</div>
				<div className={inputClassName} style={inputStyle}>
					<TextInput
						label="FAX"
						id="entityFax"
						value={roi.entityFax}
						placeholder="Fax"
						handleInput={handleChange}
						validations={[numbersOnly]}
					/>
				</div>
			</div>
			<div className={inputGroupName}>
				<div className={inputClassName} style={inputStyle}>
					<TextInput
						label="ADDRESS*"
						id="entityAddress"
						value={roi.entityAddress}
						placeholder="Address"
						handleInput={handleChange}
						validations={[required]}
					/>
				</div>
				<div className={inputClassName} style={inputStyle}>
					<TextInput
						label="ZIP CODE"
						id="entityZipcode"
						value={roi.entityZipcode}
						placeholder="Zip Code"
						handleInput={handleChange}
						validations={[numbersOnly]}
					/>
				</div>
			</div>
			<div className="px-3 pb-2">
				<p className="custom-relofInfo-text text-dark font-15">
					Please release my records to Mind Therapy Clinic at 100 Tamal Plaza
					Suite 200, Corte Madera, CA 94925.
				</p>
			</div>
		</div>
	);
};

export default EntityDetails;
